<template>
  <div>

    <v-row>
      <v-col>
        <h2 class="pb-3">{{ $t('XJlWC_8rQAUTU6aUcktTJ') }}</h2>

        <div class="caption pb-3">
          <v-icon
            size="15"
            right
          >mdi-information-outline</v-icon>
          {{ $t('74A3JNMMWth6XVI7CSxxE') }}
          <JsUrl
            :disabled="report.demo"
            :url="`https://www.similarweb.com/website/${report.domain}/`"
            :title="$t('kiWTqBh1uuYxoiG8ULOQ6')"
          >
            <v-avatar
              color="white"
              size="15"
              left
              class="ml-1 mr-1"
            >
              <img
                src="http://similarweb.com/favicon.ico"
                alt="SimilarWeb.com favicon.ico"
              >
            </v-avatar>
            <span class="text-decoration-underline">SimilarWeb.com</span>
          </JsUrl>
        </div>

        <div class="pb-3 ">{{ $t('xBXYEMuVaU84s8RKdD9SZ') }}</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h3 class="pb-2">{{ $t('z0Tf8LbJ3NcHJTX0II76B') }}</h3>
      </v-col>
    </v-row>

    <LineChart
      class="pt-2"
      :chartData="EstimatedMonthlyVisits()"
      :options="chartOptions"
    />

    <v-row class="pt-7">
      <v-col>
        <h3 class="pb-3">{{ $t('gAnTyfeo5F6tErnLVVuhL') }} {{ $moment(
            `${report.similarweb.Engagments.Year}-${report.similarweb.Engagments.Month}-01`
          ).format('MMMM YYYY') }}</h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        col="12"
        sm="8"
        xs="8"
      >
        <BarChart
          :chartData="TrafficSources()"
          :options="chartOptions"
        />
      </v-col>

      <v-col
        col="12"
        sm="4"
        xs="4"
      >

        <v-row
          v-for="(value, param, index) in [
              report.similarweb.Engagments.Visits,
              report.similarweb.Engagments.Visits / 30,
              report.similarweb.Engagments.PagePerVisit,
              report.similarweb.Engagments.TimeOnSite,
              report.similarweb.Engagments.BounceRate
          ]"
          :key="index"
          class="table-line"
        >
          <v-col
            cols="6"
            xs="6"
            sm="6"
            md="7"
            lg="7"
            class="title-table"
          >
            <template v-if="param === 0">{{ $t('4Wqw4TzpVmJ9qKLlVDOD8') }}</template>
            <template v-if="param === 1">{{ $t('u2QAgHDUGJ_0QMKIZBy45') }}</template>
            <template v-if="param === 2">{{ $t('EaCv5S6wV9Yj18MVo-XjG') }}</template>
            <template v-if="param === 3">{{ $t('oUHyPSjdYAQ-pb-oI1vy3') }}</template>
            <template v-if="param === 4">{{ $t('h20lIs-FcO_PJ5tZbrivj') }}</template>
          </v-col>
          <v-col
            cols="6"
            xs="6"
            sm="6"
            md="5"
            lg="5"
          >
            <template v-if="param === 0">
              {{ $n(Math.round(value)) }}
            </template>
            <template v-if="param === 1">
              {{ $n(Math.round(value)) }}
            </template>
            <template v-if="param === 2">
              {{ Number.parseFloat(value).toFixed(1) }}
            </template>
            <template v-if="param === 3">
              {{ new Date(value*1000).toUTCString().split(/ /)[4] }}
            </template>
            <template v-if="param === 4">
              {{ Number.parseFloat(value * 100).toFixed(2) }}%
            </template>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

    <v-row class="pt-7">
      <v-col>
        <h3 class="pb-3">{{ $t('7TbOWCIT5VvSdicOZTMWn') }}</h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        col="12"
        sm="8"
        xs="8"
      >
        <PieChart
          :chartData="TopCountryShares()"
          :options="chartOptionsPie"
        />
      </v-col>

      <v-col
        col="12"
        sm="4"
        xs="4"
      >
        <v-row
          v-for="(value, param, index) in report.similarweb.TopCountryShares"
          :key="index"
          class="table-line"
        >
          <v-col
            cols="6"
            xs="6"
            sm="6"
            md="7"
            lg="7"
            class="title-table"
          >
            <v-icon
              tag="span"
              left
            >{{ $root.flags[ $root.sw_countries[value.Country].code ].emoji }}</v-icon>{{ $root.flags[ $root.sw_countries[value.Country].code ].name }}
          </v-col>

          <v-col
            cols="6"
            xs="6"
            sm="6"
            md="5"
            lg="5"
          >
            {{ Number.parseFloat(value.Value * 100).toFixed(2) }}%
          </v-col>
        </v-row>

      </v-col>

    </v-row>

  </div>
</template>

<script>
import JsUrl from '@/components/JsUrl'
import BarChart from '@/components/reports/charts/BarChart.vue'
import LineChart from '@/components/reports/charts/LineChart.vue'
import PieChart from '@/components/reports/charts/PieChart.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'SimilarWeb',
  props: ['similarweb'],

  components: {
    JsUrl, BarChart, LineChart, PieChart
  },

  data () {
    return {
      chartOptionsPie: {
        responsive: true,
        maintainAspectRatio: false
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    }
  },

  computed: {
    ...mapGetters(['report']),
  },

  methods: {
    TopCountryShares () {
      let result = {
        labels: [], datasets: [{
          label: this.$moment(
            `${this.report.similarweb.Engagments.Year}-${this.report.similarweb.Engagments.Month}-01`
          ).format('MMMM YYYY'),
          backgroundColor: ['#6689C1', '#8aa5d1', '#afc2e0', '#d1dced', '#f2f5fa'], borderWidth: 0, data: []
        }]
      }
      for (const country of this.report.similarweb.TopCountryShares) {
        result['labels'].push(
          this.$root.flags[this.$root.sw_countries[country.Country].code].name
        )
        result['datasets'][0].data.push(
          Math.round(country.Value * 100)
        )
      }
      return result
    },
    TrafficSources () {
      let result = {
        labels: [], datasets: [{
          label: this.$moment(
            `${this.report.similarweb.Engagments.Year}-${this.report.similarweb.Engagments.Month}-01`
          ).format('MMMM YYYY'),
          backgroundColor: '#6689C1', data: []
        }]
      }
      for (const sources in this.report.similarweb.TrafficSources) {
        result['labels'].push(sources)
        result['datasets'][0].data.push(
          Math.round(this.report.similarweb.TrafficSources[sources] * 100)
        )
      }
      return result
    },

    EstimatedMonthlyVisits () {
      let result = {
        labels: [], datasets: [{
          label: this.$t('XJlWC_8rQAUTU6aUcktTJ'),
          backgroundColor: '#6689C1', data: []
        }]
      }
      for (const sources in this.report.similarweb.EstimatedMonthlyVisits) {
        result['labels'].push(
          this.$moment(sources).format('MMMM YYYY')
        )
        result['datasets'][0].data.push(
          this.report.similarweb.EstimatedMonthlyVisits[sources]
        )
      }
      return result
    }
  }
}
</script>

<style scoped>
.table-line {
  border-bottom: solid 1px rgba(125, 125, 125, 0.4);
}
.table-line:last-child {
  border-bottom: none;
}
.title-table {
  background-color: rgba(125, 125, 125, 0.1);
}
</style>