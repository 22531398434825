<template>
  <div>

    <v-row>
      <v-col>
        <h2 class="pb-3">{{ $t('S7pPXNyLNbqxNJRHh_jo4') }}</h2>

        <div class="caption pb-3">
          <v-icon
            size="15"
            right
          >mdi-information-outline</v-icon>
          {{ $t('74A3JNMMWth6XVI7CSxxE') }}
          <JsUrl
            :disabled="report.demo"
            :url="`https://majestic.com/reports/site-explorer?folder=&q=${report.domain}&IndexDataSource=F`"
            :title="$t('kiWTqBh1uuYxoiG8ULOQ6')"
          >
            <v-avatar
              color="white"
              size="15"
              left
              class="ml-1 mr-1"
            >
              <img
                src="https://www.google.com/s2/favicons?domain=majestic.com"
                alt="Majestic.com favicon.ico"
              >
            </v-avatar>
            <span class="text-decoration-underline">Majestic.com</span>
          </JsUrl>
        </div>

      </v-col>
    </v-row>

    <v-row
      v-for="(value, param, index) in {
                tf: report.majestic.tf,
                cf: report.majestic.cf,
                all_links: report.majestic.all_links,
                quality_links: report.majestic.quality_links,
                domains: report.majestic.domains,
                info_links: report.majestic.info_links,
                anchors: report.majestic.anchors,
                incoming_languages: report.majestic.incoming_languages,
          }"
      :key="index"
      class="table-line"
    >
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="4"
        class="title-table"
      >

        <template v-if="param == 'tf'">
          Trust Flow
        </template>

        <template v-if="param == 'cf'">
          Citation Flow
        </template>

        <template v-if="param == 'all_links'">
          {{ $t('PvznA-MQr_Ek3xkcPJSq4') }}
        </template>

        <template v-if="param == 'quality_links'">
          {{ $t('lFEdhYOPHIYczEkq4XgU9') }}
        </template>

        <template v-if="param == 'domains'">
          {{ $t('Mzm4aCdcMD1lYgLI2vzmh') }}
        </template>

        <template v-if="param == 'info_links'">
          {{ $t('Ocl-2vDDXCrfD1LIRM2H9') }}
        </template>

        <template v-if="param == 'anchors'">
          {{ $t('aNJpwFYjqe2aXBuLN4byM') }}
        </template>

        <template v-if="param == 'incoming_languages'">
          {{ $t('cZN-VWOs7ITO_zYrFNGfw') }}
        </template>

      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="8"
        lg="8"
      >

        <template v-if="param == 'tf' || param == 'cf'">
          {{value}}<span class="text--disabled">/100</span>
        </template>

        <template v-if="param == 'all_links' || param == 'quality_links' || param == 'domains'">
          {{ $n(value) }}
        </template>

        <template v-if="param == 'anchors'">
          <ul>
            <li
              v-for="(anchor, inx) in value"
              :key="inx"
              :class="{ 'demo-lock': anchor[0] === '$' && report.demo }"
            >{{anchor}}</li>
          </ul>
        </template>

        <template v-if="param == 'info_links'">
          <ul class="text--disabled">
            <li>Crawled URLs: <span class="white--text">{{$n(value.crawled_urls)}}</span></li>
            <li>Educational Ref. Backlinks: <span class="white--text">{{$n(value.edu_links)}}</span></li>
            <li>Governmental Ref. Backlinks: <span class="white--text">{{$n(value.gov_links)}}</span></li>
            <li>Educational Ref. Domains: <span class="white--text">{{$n(value.edu_domains)}}</span></li>
            <li>Governmental Ref. Domains: <span class="white--text">{{$n(value.gov_domains)}}</span></li>
          </ul>
        </template>

        <template v-if="param == 'incoming_languages'">
          <p><span class="text--disabled">Checked backlinks:</span> <span class="white--text">{{$n(value.checked_backlinks)}}</span></p>

          <v-row>
            <v-col
              col="12"
              sm="8"
              xs="8"
            >

              <PieChart
                :chartData="TopLangs()"
                :options="chartOptionsPie"
              />

            </v-col>

            <v-col
              col="12"
              sm="4"
              xs="4"
            >

              <v-row
                v-for="(v, p, i) in value.rang"
                :key="i"
                class="table-line"
              >
                <v-col
                  cols="6"
                  xs="6"
                  sm="6"
                  md="7"
                  lg="7"
                  class="title-table"
                >
                  {{ p === 'OTH' ? 'others' : $root.languages[p].name }}
                </v-col>
                <v-col
                  cols="6"
                  xs="6"
                  sm="6"
                  md="5"
                  lg="5"
                >
                  {{v}}%
                </v-col>
              </v-row>

            </v-col>
          </v-row>

        </template>

      </v-col>

    </v-row>

  </div>
</template>

<script>
import JsUrl from '@/components/JsUrl'
import PieChart from '@/components/reports/charts/PieChart.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Majestic',

  components: {
    JsUrl, PieChart
  },
  data () {
    return {
      chartOptionsPie: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },

  methods: {
    TopLangs () {
      let result = {
        labels: [], datasets: [{
          backgroundColor: ['#3f639b', '#466eae', '#547bba', '#6689c1', '#7897c8', '#8aa5d0', '#9cb3d7', '#afc2e0', '#d1dced', '#f2f5fa', '#ffffff'], borderWidth: 0, data: []
        }]
      }
      for (const lang in this.report.majestic.incoming_languages.rang) {
        result['labels'].push(
          lang === 'OTH' ? 'others' : this.$root.languages[lang].name
        )
        result['datasets'][0].data.push(
          Math.round(this.report.majestic.incoming_languages.rang[lang])
        )

      }

      // result['datasets'][0].data = result['datasets'][0].data.sort((a, b) => b - a)

      // console.log(result['datasets'][0].data)
      return result
    }
  },

  computed: {
    ...mapGetters(['report']),
  }
}
</script>

<style scoped>
.table-line {
  /* background-color: rgba(125, 125, 125, 0.1); */
  border-bottom: solid 1px rgba(125, 125, 125, 0.4);
}
.table-line:last-child {
  border-bottom: none;
}
.title-table {
  background-color: rgba(125, 125, 125, 0.1);
}
</style>