<script>
import { Line, mixins } from 'vue-chartjs'
import i18n from '@/locale'

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: ['chartData', 'options'],
  mounted () {
    this.renderChart(this.chartData, { ...this.options, locale: i18n.locale })
  }
}
</script>