<template>
  <div>

    <v-row>
      <v-col>
        <h2 class="pb-3">{{ $t('djPkqsWFeKF6gUPqfArUj') }}</h2>

        <div class="caption pb-3">
          <v-icon
            size="15"
            right
          >mdi-information-outline</v-icon>
          {{ $t('74A3JNMMWth6XVI7CSxxE') }}
          <JsUrl
            :disabled="report.demo"
            :url="`https://www.similarweb.com/website/${report.domain}/`"
            :title="$t('kiWTqBh1uuYxoiG8ULOQ6')"
          >
            <v-avatar
              color="white"
              size="15"
              left
              class="ml-1 mr-1"
            >
              <img
                src="http://similarweb.com/favicon.ico"
                alt="SimilarWeb.com favicon.ico"
              >
            </v-avatar>
            <span class="text-decoration-underline">SimilarWeb.com</span>
          </JsUrl>
        </div>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-center">

        <v-chip
          class="ma-1"
          v-for="site in report.similarsites"
          :key="site.Domain"
        >
          <JsUrl
            :url="`http://${site.Domain}/`"
            :title="$t('kiWTqBh1uuYxoiG8ULOQ6')"
          >
            <v-avatar
              color="white"
              size="15"
              left
            >
              <img
                :src="`${site.Favicon}`"
                :alt="`${site.Domain} favicon.ico`"
              >
            </v-avatar>
            {{site.Domain}}
          </JsUrl>
        </v-chip>

      </v-col>
    </v-row>

  </div>
</template>

<script>
import JsUrl from '@/components/JsUrl'
import { mapGetters } from 'vuex'

export default {
  name: 'SimilarSites',

  components: {
    JsUrl
  },

  computed: {
    ...mapGetters(['report']),
  }
}
</script>