<template>
  <div>

    <v-row>
      <v-col>

        <h2 class="pb-3">{{ $t('m57WN51PPUCGz5y6Cylox') }}</h2>

        <div class="pb-5">{{ $t('iOsPzCEpqBINgR--wVJyi') }}</div>

        <div class="text-center d-flex flex-wrap justify-space-around">

          <JsUrl
            :disabled="report.demo"
            v-for="service in services"
            :key="service.name"
            class="btn-line block overline pl-3 pr-3"
            :url="service.url"
            :title="$t('kiWTqBh1uuYxoiG8ULOQ6')"
          >
            <v-avatar
              color="white"
              size="20"
            >
              <img :src="service.favicon">
            </v-avatar>
            <div>{{service.name}}</div>
          </JsUrl>

        </div>

      </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import JsUrl from '@/components/JsUrl'

export default {
  components: {
    JsUrl
  },

  computed: {
    ...mapGetters(['report']),

    services () {
      return [
        {
          name: 'spyfu',
          url: `https://www.spyfu.com/overview/domain?query=${this.report.domain}`,
          favicon: 'http://spyfu.com/favicon.ico'
        },
        {
          name: 'Wayback Machine',
          url: `https://web.archive.org/web/*/${this.report.domain}`,
          favicon: 'https://web.archive.org/_static/images/archive.ico'
        },
        {
          name: 'SimilarWeb',
          url: `https://www.similarweb.com/website/${this.report.domain}`,
          favicon: 'http://similarweb.com/favicon.ico'
        },
        {
          name: 'ahrefs',
          url: `https://ahrefs.com/site-explorer/overview/v2/subdomains/live?target=${this.report.domain}`,
          favicon: 'http://ahrefs.com/favicon.ico'
        },
        {
          name: 'moz.com',
          url: `https://moz.com/domain-analysis?site=${this.report.domain}`,
          favicon: 'http://moz.com/favicon.ico'
        },
        {
          name: 'pr-cy.io',
          url: `https://pr-cy.io/${this.report.domain}`,
          favicon: 'http://pr-cy.io/assets/img/favicon.ico'
        },
        {
          name: 'majestic',
          url: `https://majestic.com/reports/site-explorer?folder=&q=${this.report.domain}&IndexDataSource=F`,
          favicon: 'https://majestic.com/favicon.ico'
        },
        {
          name: 'mywot',
          url: `https://www.mywot.com/scorecard/${this.report.domain}`,
          favicon: 'https://www.mywot.com/images/favicon.ico'
        },
        {
          name: 'hunter.io',
          url: `https://hunter.io/try/search/${this.report.domain}`,
          favicon: 'http://hunter.io/favicon.ico'
        },
        {
          name: 'buzzsumo',
          url: `https://app.buzzsumo.com/content/web?q=${this.report.domain}`,
          favicon: 'https://buzzsumo.com/wp-content/themes/brandwatch/src/site--buzzsumo.com/assets/img/favicon.ico'
        },
      ]
    }
  },
}
</script>

<style scoped>
/* .btn-line {
  border: solid 1px rgba(125, 125, 125, 0.4);
} */

/* .btn-line:last-child {
  border-right: none;
} */
</style>