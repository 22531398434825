<template>
  <div>

    <v-row>
      <v-col>
        <h2 class="pb-3">{{ $t('4LKcBwYX0h5qXUCLo0KiH') }}</h2>
        <div class="pb-3 ">{{ $t('7BsILJcddHHqV47po6uiP') }}</div>
      </v-col>
    </v-row>

    <v-row
      v-for="(value, param, index) in report.whois"
      :key="index"
      class="table-line"
    >
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="4"
        class="title-table"
      >
        {{ wordsUp(param) }}

      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="8"
        lg="8"
        class="wordwrap"
      >

        <template v-if="typeOfVal(value) === 'obj2'">
          <ul>
            <li
              v-for="(val, index) in value"
              :key="index"
            >
              <template v-if="val">
                <template v-if="param.indexOf('date') !== -1">
                  {{ $moment.unix(val).calendar() }}
                  <span class="text--disabled">({{ $moment.unix(val).fromNow() }})</span>
                </template>

                <template v-else>
                  <span :class="{ 'demo-lock': val[0] === '$' && report.demo }">
                    {{ val }}
                  </span>
                </template>
              </template>
              <template v-else><span class="text--disabled">({{ $t('yAOyOZipc3OsQbkjoTKUy') }})</span></template>
            </li>
          </ul>
        </template>
        <template v-else-if="typeOfVal(value) === 'obj1'">
          <template v-if="value[0]">
            <span :class="{ 'demo-lock': value[0][0] === '$' && report.demo }">
              {{value[0]}}
            </span>
          </template>
          <template v-else><span class="text--disabled">({{ $t('yAOyOZipc3OsQbkjoTKUy') }})</span></template>
        </template>
        <template v-else>
          <template v-if="value">

            <template v-if="param === 'country' && $root.flags[value]">
              {{ $root.flags[value].emoji }} {{ $root.flags[value].name }} ({{ value }})
            </template>

            <template v-else-if="param.indexOf('date') !== -1">
              {{ $moment.unix(value).calendar() }}
              <span class="text--disabled">({{ $moment.unix(value).fromNow() }})</span>
            </template>

            <template v-else>
              <span :class="{ 'demo-lock': value[0] === '$' && report.demo }">
                {{value}}
              </span>
            </template>

          </template>
          <template v-else><span class="text--disabled">({{ $t('yAOyOZipc3OsQbkjoTKUy') }})</span></template>
        </template>

      </v-col>

    </v-row>

  </div>
</template>

<script>
import { typeOfVal, wordsUp } from '@/mixins/utils'

import { mapGetters } from 'vuex'

export default {
  name: 'Whois',
  props: ['whois'],

  mixins: [
    typeOfVal, wordsUp
  ],

  computed: {
    ...mapGetters(['report']),
  }

}
</script>

<style scoped>
.table-line {
  /* background-color: rgba(125, 125, 125, 0.1); */
  border-bottom: solid 1px rgba(125, 125, 125, 0.4);
}
.table-line:last-child {
  border-bottom: none;
}
.title-table {
  background-color: rgba(125, 125, 125, 0.1);
}
</style>