<template>
  <v-container>

    <!-- Отображение процесса загрузки -->
    <template v-if="loading || Object.keys(report).length === 0">
      <v-text-field
        color="primary"
        loading
        disabled
      ></v-text-field>
      <v-skeleton-loader
        class="mx-auto"
        width="100%"
        type="article"
      ></v-skeleton-loader>
    </template>

    <template v-else>

      <h1 class="display-1 pb-4">
        <v-avatar
          color="primary"
          size="25"
          left
          class="mr-2"
        >
          <img
            :class="{ 'demo-lock' : report.demo}"
            :src="report.demo ? 'https://www.google.com/s2/favicons?domain=google.com' : `https://www.google.com/s2/favicons?domain=${report.domain}`"
            :alt="`${report.domain} favicon.ico`"
          >
        </v-avatar>
        <span
          :class="{ 'demo-lock' : report.demo}"
          id="website-url"
          @click="copyClip(report.domain)"
          :title="`${$t('0yLIazAyOQ7hK--ISkAcM')}`"
        >{{ report.domain }}</span>
        <JsUrl
          :disabled="report.demo"
          class="text-decoration-none"
          :url="`http://${report.domain}/`"
          :title="`${$t('kiWTqBh1uuYxoiG8ULOQ6')}`"
        >
          <v-icon right>
            mdi-open-in-new
          </v-icon>
        </JsUrl>
      </h1>

      <v-alert
        class="mt-3 mb-5 "
        dense
        border="left"
        type="info"
        color="primary"
      >
        {{ $t('ETvebsviT6MJUqCgZe20D') }} {{ $moment.unix(report.parser_timestamp).format('MMMM YYYY') }}
      </v-alert>

      <ShareLink :reportId="$route.params['reportId']" />

      <div class="subtitle-1 pb-3">
        <v-icon left>mdi-rocket-launch-outline</v-icon>{{ $t('nLpUhyVZRkFHtcJy4WuUQ') }} <b>{{ report.growth }}%</b> {{ $t('aWN21Q_cAd4CRKVgFtSYA') }} <b>{{ $moment(
            `${report.similarweb.Engagments.Year}-${report.similarweb.Engagments.Month}-01`
          ).format('MMMM YYYY') }}</b> {{ $t('BfSzOFKiVV7IBUYA5ptII') }} <b>

          <template v-if="Object.keys(report.similarweb.TopCountryShares).length">
            <v-icon tag="span">
              {{ $root.flags[ $root.sw_countries[report.similarweb.TopCountryShares[0].Country].code ].emoji }}
            </v-icon>
            {{ $root.flags[ $root.sw_countries[report.similarweb.TopCountryShares[0].Country].code ].name }}
          </template>

          <template v-else>
            {{ $route.params['country'] }}
          </template>
        </b>
      </div>

      <div class="body-2">
        <span class="pr-4">
          <v-icon left>mdi-earth</v-icon><b>{{ $t('_-rn8eIgTfyw2j10Y42Ym') }}: </b>
          <template v-if="Object.keys(report.content_info).length && report.content_info.lang && $root.languages[report.content_info.lang]">
            {{ $root.languages[report.content_info.lang].name }} ({{ $root.languages[report.content_info.lang].native }})
          </template>
          <template v-else><span class="text--disabled">({{ $t('yAOyOZipc3OsQbkjoTKUy') }})</span></template>
        </span>
        <span>
          <v-icon left>mdi-tag-outline</v-icon><b>{{ $t('7BqWPh8pNGHyTbilFkVSu') }}: </b>

          <template v-if="report.categories && Object.keys(report.categories).length">
            {{ report.categories.join(', ') }}
          </template>
          <template v-else>
            <span class="text--disabled">({{ $t('yAOyOZipc3OsQbkjoTKUy') }})</span>
          </template>

        </span>
      </div>

      <template v-if="Object.keys(report.content_info).length">
        <v-row class="pt-10">
          <v-col
            col="12"
            sm="4"
            xs="4"
          >
            <v-img
              :class="{ 'demo-lock' : report.demo}"
              :src="report.demo ? demoScreens[Math.floor(Math.random() * demoScreens.length)] : report.similarweb.LargeScreenshot"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>

          <v-col
            col="12"
            sm="8"
            xs="8"
          >
            <div>
              <v-icon
                left
                color="grey"
                size="24"
                class="pb-2"
                title="<title>"
              >mdi-alpha-t-box-outline</v-icon>
              <template v-if="report.content_info && report.content_info.title">
                <span
                  class="title pb-3"
                  :class="{ 'demo-lock': report.content_info && report.content_info.title && report.content_info.title[0] === '$' && report.demo }"
                >{{ report.content_info.title }}</span>
              </template>
              <template v-else><span class="text--disabled">({{ $t('yAOyOZipc3OsQbkjoTKUy') }})</span></template>
            </div>

            <div>
              <v-icon
                left
                color="grey"
                size="24"
                class="pb-2"
                title="<meta name='description'>"
              >mdi-card-text-outline</v-icon>
              <template v-if="report.content_info && report.content_info.description">
                <span :class="{ 'demo-lock': report.content_info && report.content_info.description && report.content_info.description[0] === '$' && report.demo }">
                  {{ report.content_info.description }}
                </span>
              </template>
              <template v-else><span class="text--disabled">({{ $t('yAOyOZipc3OsQbkjoTKUy') }})</span></template>
            </div>
          </v-col>
        </v-row>
      </template>

      <template v-else>
        <v-alert
          class="mt-10"
          dense
          border="left"
          type="error"
        >
          {{ $t('vPiWvL6sxxo9KfIkqpLfM') }}
        </v-alert>
      </template>

      <template v-if="$root.country === 'RU' || $i18n.locale === 'ru'">
        <!-- Yandex.RTB R-A-1305157-3 -->
        <div id="yandex_rtb_R-A-1305157-3"></div>
        <script2>window.yaContextCb.push(()=>{
          Ya.Context.AdvManager.render({
          renderTo: 'yandex_rtb_R-A-1305157-3',
          blockId: 'R-A-1305157-3'
          })
          })</script2>
      </template>
      <template v-else>
        <Adsense
          class="pt-15"
          data-ad-client="ca-pub-5616127056097007"
          data-ad-slot="4003280803"
        >
        </Adsense>
      </template>

      <SimilarWeb
        id="SimilarWeb"
        class="pt-15"
        v-if="report.similarweb && Object.keys(report.similarweb).length"
      />

      <Majestic
        id="Majestic"
        class="pt-15"
        v-if="report.majestic && Object.keys(report.majestic).length"
      />

      <MozCom
        id="MozCom"
        class="pt-15"
        v-if="report.moz && Object.keys(report.moz).length"
      />

      <Google
        id="Google"
        class="pt-15"
        v-if="report.google && Object.keys(report.google).length"
      />

      <MyWot
        id="MyWot"
        class="pt-15"
        v-if="report.mywot && Object.keys(report.mywot).length"
      />

      <template v-if="$root.country === 'RU' || $i18n.locale === 'ru'">
        <!-- Yandex.RTB R-A-1305157-3 -->
        <div id="yandex_rtb_R-A-1305157-3"></div>
        <script2>window.yaContextCb.push(()=>{
          Ya.Context.AdvManager.render({
          renderTo: 'yandex_rtb_R-A-1305157-3',
          blockId: 'R-A-1305157-3'
          })
          })</script2>
      </template>
      <template v-else>
        <Adsense
          class="pt-15"
          data-ad-client="ca-pub-5616127056097007"
          data-ad-slot="4194852497"
        >
        </Adsense>
      </template>

      <Content
        id="Content"
        class="pt-15"
        :content="report.content_info"
        v-if="report.content_info && Object.keys(report.content_info).length"
      />

      <WebArchive
        id="WebArchive"
        class="pt-15"
        v-if="report.web_archive && Object.keys(report.web_archive).length"
      />

      <WebsiteIds
        id="WebsiteIds"
        class="pt-15"
        v-if="report.website_ids && Object.keys(report.website_ids).length"
      />

      <BuiltWith
        id="BuiltWith"
        class="pt-15"
        :builtwith="report.builtwith"
        v-if="report.builtwith && Object.keys(report.builtwith).length"
      />

      <Whois
        id="Whois"
        class="pt-15"
        v-if="report.whois && Object.keys(report.whois).length"
      />

      <SEO
        id="SEO"
        class="pt-15"
      />

      <SimilarSites
        id="SimilarSites"
        class="pt-15"
        v-if="report.similarsites && Object.keys(report.similarsites).length"
      />

      <template v-if="$root.country === 'RU' || $i18n.locale === 'ru'">
        <!-- Yandex.RTB R-A-1305157-3 -->
        <div id="yandex_rtb_R-A-1305157-3"></div>
        <script2>window.yaContextCb.push(()=>{
          Ya.Context.AdvManager.render({
          renderTo: 'yandex_rtb_R-A-1305157-3',
          blockId: 'R-A-1305157-3'
          })
          })</script2>
      </template>
      <template v-else>
        <Adsense
          class="pt-15"
          data-ad-client="ca-pub-5616127056097007"
          data-ad-slot="4386424188"
        >
        </Adsense>
      </template>

      <!-- <SeeAlso
        class="pt-15"
        :month="`${report.similarweb.Engagments.Year}-${report.similarweb.Engagments.Month}`"
        :country="report.similarweb.TopCountryShares[0].Country"
        :reportId="$route.params['reportId']"
      /> -->

    </template>

  </v-container>
</template>

<script>
import JsUrl from '@/components/JsUrl'
import SimilarWeb from '@/components/reports/SimilarWeb'
import SimilarSites from '@/components/reports/SimilarSites'
import Google from '@/components/reports/Google'
import WebArchive from '@/components/reports/WebArchive'
import Content from '@/components/reports/Content'
import BuiltWith from '@/components/reports/BuiltWith'
import Whois from '@/components/reports/Whois'
import SEO from '@/components/reports/SEO'
// import SeeAlso from '@/components/reports/SeeAlso'
import MyWot from '@/components/reports/MyWot'
import Majestic from '@/components/reports/Majestic'
import MozCom from '@/components/reports/MozCom'
import WebsiteIds from '@/components/reports/WebsiteIds'

import ShareLink from '@/components/ShareLink'

import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    JsUrl,
    SimilarWeb,
    // SeeAlso,
    SimilarSites,
    MozCom,
    Content,
    Google,
    WebArchive,
    BuiltWith,
    Whois,
    SEO,
    MyWot,
    Majestic,
    WebsiteIds,
    ShareLink
  },

  data () {
    return {
      demoScreens: [
        // случайный список скринов для демо-режима
        "https://site-images.similarcdn.com/image?url=anyword.com&t=1&h=d39922bea7f66831728c1fea2f9906806be245bf039f917d3fff626d0f1f11cf",
        "https://site-images.similarcdn.com/image?url=aps.org&t=1&h=6c2c2d16f2ea2a70bf842ae5123d7dafe1c582a58c621f05931bd4295b520ffd",
        "https://site-images.similarcdn.com/image?url=thefader.com&t=1&h=33e0658cd06c1220c1ae9d4db07fbad584de67986f77d1b70b444d548c1996bd",
        "https://site-images.similarcdn.com/image?url=gamersdecide.com&t=1&h=ec98a09b8cadaf1ac51a76a50c787b4176e66b34f15b92243f65033d95854109",
        "https://site-images.similarcdn.com/image?url=tfc.tv&t=1&h=21f8be6801144ae7182ebf83bcc5d406348c78ad32642fb8b57caeaaa70d9a79",
        "https://site-images.similarcdn.com/image?url=babestare.com&t=1&h=746c19d77766357703394b13056cceac5dc01894d6e57b36644febf97f9e3a2e",
        "https://site-images.similarcdn.com/image?url=kinovasek.org&t=1&h=716b35388157139063faec91a7825b92201c021359d6dabc61c2579f7ff7394e"
      ]
    }
  },

  computed: {
    ...mapGetters(['report', 'loading']),
  },

  mounted () {
    this.getReport({
      reportId: this.$route.params['reportId'], // добавляем 24 часа
    })
  },

  methods: {
    ...mapActions(['getReport']),

    demoLockMsg () {
      this.$store.dispatch('setMessage', { type: 'error', to: '/plans', text: this.$t('UkDUn9_bZ_s3oA7ykZ2CE') })
    },

    copyClip (str) {
      /** Копирование текста в буфер обмена
       */
      if (this.report.demo) {
        return
      }
      const area = document.createElement('textarea')
      document.body.appendChild(area)
      area.value = str
      area.select()
      document.execCommand("copy")
      document.body.removeChild(area)

      this.$store.dispatch('setMessage', { text: this.$t('U1OYgDWXWx5CdodRzUe5S'), type: 'success' })
    }
  },

  beforeUpdate () {
    this.$nextTick(function () {
      if (!this.loading && this.report && this.report.demo) {
        const elements = document.querySelectorAll('.demo-lock')
        for (let el of elements) {
          el.addEventListener('click', this.demoLockMsg)
        }
        const elements2 = document.querySelectorAll('.disabled-link')
        for (let el of elements2) {
          el.addEventListener('click', this.demoLockMsg)
        }
      }
    })
  }

}
</script>

<style scoped>
#website-url {
  text-decoration: none;
  border-bottom: 2px dashed #757575;
  cursor: pointer;
}
</style>