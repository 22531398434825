export const typeOfVal = {
    /** Определение типа значения: 
    объект с одним элементом, несколькими или просто строка
     */
    methods: {
        typeOfVal: function (value) {
            if (
                value !== null &&
                typeof value === 'object' &&
                value.length <= 1
            ) {
                // объект с одним элементом
                return 'obj1'
            } else if (
                value !== null &&
                typeof value === 'object' &&
                value.length > 1
            ) {
                // объект с несколькими элементами
                return 'obj2'
            } else {
                // объект строка
                return 'str'
            }
        }
    }
}

export const wordsUp = {
    /** Если в слове меньше 4 символов, то это аббревиатура
    Если больше, то каждое слово с большой буквы
     */
    methods: {
        wordsUp: function (string) {
            if (string.length < 4) {
                return string.toUpperCase()
            } else {
                return string
                    .replace('-', ' ')
                    .replace('_', ' ')
                    .split(/\s+/)
                    .map(word => word[0].toUpperCase() + word.substring(1))
                    .join(' ')
            }
        }
    }
}
