<template>
  <div>

    <v-row>
      <v-col>
        <h2 class="pb-3">{{ $t('9zqxaLlMF8QMcGv43II4b') }}</h2>
        <div class="pb-3 ">{{ $t('9lPdfocjJTT3x_9-Awenc') }}</div>
      </v-col>
    </v-row>

    <v-row
      v-for="(value, param, index) in builtwith"
      :key="index"
      class="table-line"
    >
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="4"
        class="title-table"
      >
        {{ wordsUp(param) }}
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="8"
        lg="8"
      >

        <template v-if="typeOfVal(value) === 'obj2'">
          <ul>
            <li
              v-for="(val, index) in value"
              :key="index"
              class="pb-2"
            >
              <template v-if="val">

                <v-avatar
                  color="white"
                  size="15"
                  left
                  class="mr-2"
                >
                  <img
                    :src="'https://www.google.com/s2/favicons?domain='+val.website"
                    :alt="val.name"
                  >
                </v-avatar>

                <JsUrl
                  class="text-decoration-underline"
                  :url="val.website"
                  :title="$t('kiWTqBh1uuYxoiG8ULOQ6')"
                >{{ val.name }}</JsUrl>

                <span class="pl-2 text--disabled">{{ val.description }}</span>

              </template>
              <template v-else><span class="text--disabled">({{ $t('yAOyOZipc3OsQbkjoTKUy') }})</span></template>
            </li>
          </ul>
        </template>

        <template v-else-if="typeOfVal(value) === 'obj1'">
          <template v-if="value[0]">

            <v-avatar
              color="white"
              size="15"
              left
              class="mr-2"
            >
              <img
                :src="'https://www.google.com/s2/favicons?domain='+value[0].website"
                :alt="value[0].name"
              >
            </v-avatar>

            <JsUrl
              class="text-decoration-underline"
              :url="value[0].website"
              :title="$t('kiWTqBh1uuYxoiG8ULOQ6')"
            >{{ value[0].name }}</JsUrl>

            <span class="pl-2 text--disabled">{{ value[0].description }}</span>

          </template>
          <template v-else><span class="text--disabled">({{ $t('yAOyOZipc3OsQbkjoTKUy') }})</span></template>
        </template>

        <template v-else>
          <template v-if="value">{{ value }}</template>
          <template v-else><span class="text--disabled">({{ $t('yAOyOZipc3OsQbkjoTKUy') }})</span></template>
        </template>

      </v-col>

    </v-row>

  </div>
</template>

<script>
import JsUrl from '@/components/JsUrl'
import { typeOfVal, wordsUp } from '@/mixins/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'BuiltWith',

  props: ['builtwith'], // передаем в компанент, чтобы брать данные из разных веток отчета 

  components: {
    JsUrl
  },

  mixins: [
    typeOfVal, wordsUp
  ],

  computed: {
    ...mapGetters(['report']),
  }
}
</script>

<style scoped>
.table-line {
  /* background-color: rgba(125, 125, 125, 0.1); */
  border-bottom: solid 1px rgba(125, 125, 125, 0.4);
}
.table-line:last-child {
  border-bottom: none;
}
.title-table {
  background-color: rgba(125, 125, 125, 0.1);
}
</style>