<template>
  <div class="share-links">
    <div class="overline text-center">Save the link!</div>
    <v-bottom-navigation
      class="share-line"
      elevation="0"
    >
      <v-btn
        v-for="link in links"
        :key="link.url"
        tag="a"
        :href="link.url"
        target="_blank"
        rel="nofollow noreferrer noopener"
        @click="clickShare(link.text)"
      >
        <span>{{link.text}}</span>
        <v-icon>{{link.icon}}</v-icon>
      </v-btn>

    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  name: 'ShareLink',
  props: ['reportId'],
  data () {
    return {

    }
  },

  methods: {
    clickShare (socialLink) {
      this.$gtm.trackEvent({
        event: 'SHARE_LINK_CLICK',
        'share.social': socialLink,
        'share.page': this.reportId
      })
    }
  },

  computed: {
    links () {
      return [
        { text: 'Facebook', icon: 'mdi-facebook', url: 'https://www.facebook.com/sharer.php?src=sp&u=' + encodeURIComponent(`https://whatsfy.com/app/report/${this.reportId}?utm_source=facebook&utm_medium=social&utm_campaign=share_buttons&utm_content=${this.reportId}`) },

        { text: 'Twitter', icon: 'mdi-twitter', url: 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(`https://whatsfy.com/app/report/${this.reportId}?utm_source=twitter&utm_medium=social&utm_campaign=share_buttons&utm_content=${this.reportId}`) },

        { text: 'WhatsApp', icon: 'mdi-whatsapp', url: 'https://api.whatsapp.com/send?text=' + encodeURIComponent(`https://whatsfy.com/app/report/${this.reportId}?utm_source=whatsapp&utm_medium=social&utm_campaign=share_buttons&utm_content=${this.reportId}`) },

        { text: 'Reddit', icon: 'mdi-reddit', url: 'https://www.reddit.com/submit?url=' + encodeURIComponent(`https://whatsfy.com/app/report/${this.reportId}?utm_source=reddit&utm_medium=social&utm_campaign=share_buttons&utm_content=${this.reportId}`) },

        { text: 'Telegram', icon: 'mdi-send', url: 'https://telegram.me/share/url?url=' + encodeURIComponent(`https://whatsfy.com/app/report/${this.reportId}?utm_source=telegram&utm_medium=social&utm_campaign=share_buttons&utm_content=${this.reportId}`) }
      ]
    }

  }
}
</script>

<style scoped>
.share-links {
  padding-bottom: 40px;
}
.share-line {
  box-shadow: none;
}
</style>
