<template>
  <div>

    <v-row>
      <v-col>
        <h2 class="pb-3">{{ $t('0WSnY47-VKLNQCNi-8NWh') }}</h2>
        <div class="pb-3 ">{{ $t('81dCBAQnmOk0BhZOS_4oI') }}</div>
      </v-col>
    </v-row>

    <v-row
      v-for="(value, param, index) in report.google"
      :key="index"
      class="table-line"
    >
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="4"
        class="title-table"
      >
        <template v-if="param === 'pages'">
          <v-avatar
            color="primary"
            size="15"
            left
            class="mr-2"
          >
            <img
              src="https://www.google.com/s2/favicons?domain=google.com"
              :alt="$t('Qm70Io67UjqsmLRPuyAwr')"
            >
          </v-avatar>
          <JsUrl
            :disabled="report.demo"
            class="text-decoration-underline"
            :url="`https://www.google.com/search?q=site%3A${report.domain}&gl=us&hl=en`"
            :title="$t('kiWTqBh1uuYxoiG8ULOQ6')"
          >{{$t('Qm70Io67UjqsmLRPuyAwr')}}:</JsUrl>
        </template>

        <template v-else-if="param === 'mentions'">
          <v-avatar
            color="primary"
            size="15"
            left
            class="mr-2"
          >
            <img
              src="https://www.google.com/s2/favicons?domain=google.com"
              :alt="$t('myVoQpryFlyJvUlZkX31Q')"
            >
          </v-avatar>
          <JsUrl
            :disabled="report.demo"
            class="text-decoration-underline"
            :url="`https://www.google.com/search?q=%22${report.domain}%22&gl=us&hl=en`"
            :title="$t('kiWTqBh1uuYxoiG8ULOQ6')"
          >{{$t('myVoQpryFlyJvUlZkX31Q')}}:</JsUrl>
        </template>

        <template v-else-if="param === 'cache'">
          <v-avatar
            color="primary"
            size="15"
            left
            class="mr-2"
          >
            <img
              src="https://www.google.com/s2/favicons?domain=google.com"
              :alt="$t('Jx7u13OdXcyasmKqBGP5L')"
            >
          </v-avatar>
          <JsUrl
            :disabled="report.demo"
            class="text-decoration-underline"
            :url="`https://webcache.googleusercontent.com/search?q=cache:${report.domain}`"
            :title="$t('kiWTqBh1uuYxoiG8ULOQ6')"
          >{{$t('Jx7u13OdXcyasmKqBGP5L')}}:</JsUrl>
        </template>

      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="8"
        lg="8"
      >
        <template v-if="param === 'cache'">
          <v-icon
            v-if="value"
            size="20"
            right
          >mdi-check-circle</v-icon>
          <v-icon
            v-else
            size="20"
            right
          >mdi-close-circle</v-icon>

        </template>

        <template v-else>
          {{ $n(value) }}
        </template>

      </v-col>

    </v-row>

  </div>
</template>

<script>
import JsUrl from '@/components/JsUrl'
import { mapGetters } from 'vuex'

export default {
  name: 'Google',
  props: ['google'],

  components: {
    JsUrl
  },

  computed: {
    ...mapGetters(['report']),
  }
}
</script>

<style scoped>
.table-line {
  /* background-color: rgba(125, 125, 125, 0.1); */
  border-bottom: solid 1px rgba(125, 125, 125, 0.4);
}
.table-line:last-child {
  border-bottom: none;
}
.title-table {
  background-color: rgba(125, 125, 125, 0.1);
}
</style>