<template>
  <div>

    <v-row>
      <v-col>
        <h2 class="pb-3">{{ $t('PNQm4YKK_ilIA1ZaDThUB') }}</h2>

        <div class="caption pb-3">
          <v-icon
            size="15"
            right
          >mdi-information-outline</v-icon>
          {{ $t('74A3JNMMWth6XVI7CSxxE') }}
          <JsUrl
            :disabled="report.demo"
            :url="`https://moz.com/domain-analysis?site=${report.domain}`"
            :title="$t('kiWTqBh1uuYxoiG8ULOQ6')"
          >
            <v-avatar
              color="white"
              size="15"
              left
              class="ml-1 mr-1"
            >
              <img
                src="https://www.google.com/s2/favicons?domain=moz.com"
                alt="Moz.com favicon.ico"
              >
            </v-avatar>
            <span class="text-decoration-underline">Moz.com</span>
          </JsUrl>
        </div>

      </v-col>
    </v-row>

    <v-row
      v-for="(value, param, index) in {
                domain_authority: report.moz.domain_authority,
                page_authority: report.moz.page_authority,
                spam_score: report.moz.spam_score,
                external_nofollow_urls_to_url: report.moz.external_nofollow_urls_to_url,
                external_urls_to_url: report.moz.external_urls_to_url
          }"
      :key="index"
      class="table-line"
    >
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="4"
        class="title-table"
      >

        <template v-if="param == 'domain_authority'">
          Domain Authority <span class="text--disabled">(DA)</span>
        </template>

        <template v-if="param == 'page_authority'">
          Page Authority <span class="text--disabled">(PA)</span>
        </template>

        <template v-if="param == 'spam_score'">
          Spam Score
        </template>

        <template v-if="param == 'external_nofollow_urls_to_url'">
          External Nofollow URLs
        </template>

        <template v-if="param == 'external_urls_to_url'">
          External URLs
        </template>

      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="8"
        lg="8"
      >

        <template v-if="param == 'domain_authority' || param == 'page_authority' || param == 'spam_score'">
          {{value}}<span class="text--disabled">/100</span>
        </template>

        <template v-if="param == 'external_nofollow_urls_to_url' || param == 'external_urls_to_url'">
          {{ $n(value) }}
        </template>

      </v-col>

    </v-row>

  </div>
</template>

<script>
import JsUrl from '@/components/JsUrl'
import { mapGetters } from 'vuex'

export default {
  name: 'MozCom',

  components: {
    JsUrl
  },
  data () {
    return {
    }
  },

  computed: {
    ...mapGetters(['report']),
  }
}
</script>

<style scoped>
.table-line {
  /* background-color: rgba(125, 125, 125, 0.1); */
  border-bottom: solid 1px rgba(125, 125, 125, 0.4);
}
.table-line:last-child {
  border-bottom: none;
}
.title-table {
  background-color: rgba(125, 125, 125, 0.1);
}
</style>