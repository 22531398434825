<template>
  <div>

    <v-row>
      <v-col>
        <h2 class="pb-3">{{ $t('XFp5XDX2vvzJ8210vJ5QV') }}</h2>
        <div class="pb-3 ">{{ $t('1wgPTfFGep2wfM-12o0pU') }}</div>
      </v-col>
    </v-row>

    <v-row
      v-for="(value, param, index) in report.website_ids"
      :key="index"
      class="table-line"
    >
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="4"
        class="title-table"
      >
        <v-avatar
          color="white"
          size="15"
          left
          class="mr-2"
        >
          <img
            :src="'https://www.google.com/s2/favicons?domain='+value.website"
            :alt="value.name"
          >
        </v-avatar>

        <JsUrl
          class="text-decoration-underline"
          :url="value.website"
          :title="$t('kiWTqBh1uuYxoiG8ULOQ6')"
        >{{ value.name }}</JsUrl>

      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="8"
        lg="8"
      >
        <ul>
          <li
            @click="copyClip(val)"
            v-for="(val, index) in value.ids"
            :key="index"
            class="pb-2"
          >
            <span
              class="copy-text"
              :class="{ 'demo-lock' : report.demo}"
            >{{val}}</span>
          </li>
        </ul>
      </v-col>

    </v-row>

  </div>
</template>

<script>
import JsUrl from '@/components/JsUrl'
import { typeOfVal, wordsUp } from '@/mixins/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'WebsiteIds',

  components: {
    JsUrl
  },

  mixins: [
    typeOfVal, wordsUp
  ],

  computed: {
    ...mapGetters(['report']),
  },

  methods: {
    copyClip (str) {
      /** Копирование текста в буфер обмена
       */
      if (this.report.demo) {
        return
      }
      const area = document.createElement('textarea')
      document.body.appendChild(area)
      area.value = str
      area.select()
      document.execCommand("copy")
      document.body.removeChild(area)

      this.$store.dispatch('setMessage', { text: this.$t('_coKnk48NYO0qDIm3g8nQ'), type: 'success' })
    }
  }
}
</script>

<style scoped>
.table-line {
  /* background-color: rgba(125, 125, 125, 0.1); */
  border-bottom: solid 1px rgba(125, 125, 125, 0.4);
}
.table-line:last-child {
  border-bottom: none;
}
.title-table {
  background-color: rgba(125, 125, 125, 0.1);
}
.copy-text {
  text-decoration: none;
  border-bottom: 2px dashed #757575;
  cursor: pointer;
}
</style>
