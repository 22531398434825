<template>
  <div>

    <v-row>
      <v-col>
        <h2 class="pb-3">{{ $t('K_jMTt4qmAm3IrOUOL0-z') }}</h2>

        <div class="caption pb-3">
          <v-icon
            size="15"
            right
          >mdi-information-outline</v-icon>
          {{ $t('74A3JNMMWth6XVI7CSxxE') }}
          <JsUrl
            :disabled="report.demo"
            :url="`https://www.mywot.com/scorecard/${report.domain}`"
            :title="$t('kiWTqBh1uuYxoiG8ULOQ6')"
          >
            <v-avatar
              color="white"
              size="15"
              left
              class="ml-1 mr-1"
            >
              <img
                src="https://www.google.com/s2/favicons?domain=mywot.com"
                alt="mywot.com favicon.ico"
              >
            </v-avatar>
            <span class="text-decoration-underline">MyWOT.com</span>
          </JsUrl>
        </div>

      </v-col>
    </v-row>

    <v-row
      v-for="(value, param, index) in {
            rating: report.mywot.rating,
            safety: report.mywot.safety,
            categories: report.mywot.categories
        }"
      :key="index"
      class="table-line"
    >
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="4"
        class="title-table"
      >

        <template v-if="param == 'rating'">
          {{ $t('lBM_uSB2e7wWPW8w3VGqZ') }}
        </template>

        <template v-else-if="param === 'safety'">
          {{ $t('DHjEV14y0Dkeu_l-Wz-OD') }}
        </template>

        <template v-else-if="param === 'categories'">
          {{ $t('omErmp8bSVn_vAIyJdw4H') }}
        </template>

      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="8"
        lg="8"
      >

        <template v-if="param == 'rating'">
          <span class="text--disabled">{{value.score}}</span>
          <v-rating
            class="d-inline"
            v-model="value.stars"
            background-color="orange lighten-3"
            color="orange"
            length="5"
            readonly
            small
          ></v-rating>
          <span
            v-if="value.reviewsCount"
            class="text--disabled"
          >({{value.reviewsCount}} Reviews)</span>
        </template>

        <template v-else-if="param == 'safety'">
          <ul class="text--disabled">
            <li>{{ $t('pc5F4oQ2eS7NeMTJLPQs8') }}: <span class="white--text">{{value.status}}</span></li>
            <li>{{ $t('QqynfKCX6dZUufcj6Zr-L') }}: <span class="white--text">{{value.reputations}}%</span></li>
            <li>{{ $t('gOgHnTQl4HrrSMYMxjCRq') }}: <span class="white--text">{{value.confidence}}%</span></li>
          </ul>
        </template>

        <template v-else-if="param == 'categories'">
          <template v-if="value.length">
            <v-chip
              class="ma-1"
              v-for="cat in value"
              :key="cat.name"
            >
              {{cat.name}}
            </v-chip>
          </template>
          <template v-else><span class="text--disabled">({{ $t('yAOyOZipc3OsQbkjoTKUy') }})</span></template>
        </template>

      </v-col>

    </v-row>

  </div>
</template>

<script>
import JsUrl from '@/components/JsUrl'
import { mapGetters } from 'vuex'

export default {
  name: 'MyWot',

  components: {
    JsUrl
  },

  computed: {
    ...mapGetters(['report']),
  }
}
</script>

<style scoped>
.table-line {
  /* background-color: rgba(125, 125, 125, 0.1); */
  border-bottom: solid 1px rgba(125, 125, 125, 0.4);
}
.table-line:last-child {
  border-bottom: none;
}
.title-table {
  background-color: rgba(125, 125, 125, 0.1);
}
</style>