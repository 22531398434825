<template>
  <div>

    <v-row>
      <v-col>
        <h2 class="pb-3">{{ $t('SWmrQ4NlcZQOk6fl3kJoS') }}</h2>
        <div class="pb-3 ">{{ $t('iEhu-T3AUZz7ASB3kYcB4') }}</div>
      </v-col>
    </v-row>

    <!-- тут приходится делать костель, чтобы отсортировать список -->
    <v-row
      v-for="(value, param, index) in {
          lang:           content.lang,
          hreflangs:      content.hreflangs,
          length_symbols: content.length_symbols,
          length_words:   content.length_words,
          cloud_words:    content.cloud_words,
          title:          content.title,
          description:    content.description,
          author:         content.author,
          copyright:      content.copyright,
          generator:      content.generator,
          keywords:       content.keywords,
          h1:             content.h1,
          h2:             content.h2,
          h3:             content.h3,
          h4:             content.h4,
          h5:             content.h5,
          h6:             content.h6, 
      }"
      :key="index"
      class="table-line"
    >
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="4"
        class="title-table"
      >

        <template v-if="param === 'lang'">{{ $t('KsSJRC2ltFzervikZfbEJ') }}</template>
        <template v-else-if="param === 'hreflangs'">{{ $t('F3xahmjLpxQzMaLyagPBg') }}</template>
        <template v-else-if="param === 'title'">&lt;title&gt;</template>
        <template v-else-if="param === 'description'">&lt;meta name=&quot;description&quot;&gt;</template>
        <template v-else-if="param === 'author'">&lt;meta name=&quot;author&quot;&gt;</template>
        <template v-else-if="param === 'copyright'">&lt;meta name=&quot;copyright&quot;&gt;</template>
        <template v-else-if="param === 'generator'">&lt;meta name=&quot;generator&quot;&gt;</template>
        <template v-else-if="param === 'keywords'">&lt;meta name=&quot;keywords&quot;&gt;</template>
        <template v-else-if="param === 'h1'">&lt;h1&gt;</template>
        <template v-else-if="param === 'h2'">&lt;h2&gt;</template>
        <template v-else-if="param === 'h3'">&lt;h3&gt;</template>
        <template v-else-if="param === 'h4'">&lt;h4&gt;</template>
        <template v-else-if="param === 'h5'">&lt;h5&gt;</template>
        <template v-else-if="param === 'h6'">&lt;h6&gt;</template>
        <template v-else-if="param === 'length_symbols'">{{ $t('yjISy7rlcfG5TuekXe_Od') }}</template>
        <template v-else-if="param === 'length_words'">{{ $t('U7AjOeLlmjjMksCfdRKT0') }}</template>
        <template v-else-if="param === 'cloud_words'">{{ $t('fD9U0FPb8Y3nb-o0hxXuS') }}</template>

      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="8"
        lg="8"
        class="wordwrap"
      >
        <template v-if="param === 'cloud_words'">
          <div class="cloud-words text-center">
            <span
              v-for="(w, i) in value"
              :key="i"
              :class="'cloud-size-'+w.size"
            >
              <span :class="{ 'demo-lock': w.word[0] === '$' && report.demo }">
                {{ w.word }}
              </span>
            </span>
          </div>
        </template>

        <template v-else-if="param === 'hreflangs'">

          <template v-if="value">
            <span
              v-for="(lang, indx) in value"
              :key="indx"
            >
              <JsUrl
                v-if="lang !== null"
                :disabled="report.demo"
                :url="lang.link"
              ><span class="text-decoration-underline">
                  {{ $root.languages[lang.lang.slice(0, 2)].name }}
                  <span v-if="lang.lang.slice(3, 5)">
                    / {{ $root.flags[lang.lang.slice(3, 5).toUpperCase()].emoji }} {{ $root.flags[lang.lang.slice(3, 5).toUpperCase()].name }}
                  </span>
                </span>&nbsp;&middot;&nbsp;
              </JsUrl>
            </span>
            <span class="text--disabled">({{ value.length }})</span>
          </template>
          <template v-else><span class="text--disabled">({{ $t('yAOyOZipc3OsQbkjoTKUy') }})</span></template>
        </template>

        <template v-else-if="param === 'lang'">
          <template v-if="value && $root.languages[value]">
            {{ $root.languages[value].name }} <span class="text--disabled">({{ $root.languages[value].native }})</span>
          </template>
          <template v-else><span class="text--disabled">({{ $t('yAOyOZipc3OsQbkjoTKUy') }})</span></template>
        </template>

        <template v-else>
          <template v-if="typeOfVal(value) === 'obj2'">
            <ul>
              <li
                v-for="(val, index) in value"
                :key="index"
              >
                <template v-if="val">
                  <span :class="{ 'demo-lock': val[0] === '$' && report.demo }">
                    {{ val }}
                  </span>
                </template>
                <template v-else><span class="text--disabled">({{ $t('yAOyOZipc3OsQbkjoTKUy') }})</span></template>
              </li>
            </ul>
          </template>
          <template v-else-if="typeOfVal(value) === 'obj1'">
            <template v-if="value[0]">
              <span :class="{ 'demo-lock': value[0][0] === '$' && report.demo }">
                {{ value[0] }}
              </span>
            </template>
            <template v-else><span class="text--disabled">({{ $t('yAOyOZipc3OsQbkjoTKUy') }})</span></template>
          </template>
          <template v-else>
            <template v-if="value">
              <template v-if="typeof(value) === 'number'">{{ $n(value) }}</template>
              <template v-else>
                <span :class="{ 'demo-lock': value[0] === '$' && report.demo }">
                  {{ value }}
                </span>
                <span class="text--disabled">({{ value.length }})</span></template>
            </template>
            <template v-else><span class="text--disabled">({{ $t('yAOyOZipc3OsQbkjoTKUy') }})</span></template>
          </template>
        </template>

      </v-col>

    </v-row>

  </div>
</template>

<script>
import JsUrl from '@/components/JsUrl'
import { typeOfVal } from '@/mixins/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'Content',

  props: ['content'], // передаем в компанент, чтобы брать данные из разных веток отчета 

  components: {
    JsUrl
  },

  mixins: [
    typeOfVal
  ],

  computed: {
    ...mapGetters(['report']),
  }
}
</script>

<style scoped>
.table-line {
  /* background-color: rgba(125, 125, 125, 0.1); */
  border-bottom: solid 1px rgba(125, 125, 125, 0.4);
}
.table-line:last-child {
  border-bottom: none;
}
.title-table {
  background-color: rgba(125, 125, 125, 0.1);
}
.cloud-words {
  line-height: 1.4em;
}
[class^="cloud-size-"] {
  opacity: 1;
  font-size: 3.1em;
}
.cloud-size-1 {
  opacity: 0.4;
  font-size: 0.9em;
}
.cloud-size-2 {
  opacity: 0.5;
  font-size: 1.3em;
}
.cloud-size-3 {
  opacity: 0.7;
  font-size: 1.5em;
}
.cloud-size-4 {
  opacity: 0.9;
  font-size: 1.7em;
}
.cloud-size-5 {
  opacity: 0.95;
  font-size: 1.9em;
}
.cloud-size-6 {
  opacity: 1;
  font-size: 2.1em;
}
.cloud-size-7 {
  opacity: 1;
  font-size: 2.2em;
}
.cloud-size-8 {
  opacity: 1;
  font-size: 2.3em;
}
.cloud-size-9 {
  opacity: 1;
  font-size: 2.4em;
}
.cloud-size-10 {
  opacity: 1;
  font-size: 2.5em;
}
.cloud-size-11 {
  opacity: 1;
  font-size: 2.6em;
}
.cloud-size-11 {
  opacity: 1;
  font-size: 2.7em;
}
.cloud-size-12 {
  opacity: 1;
  font-size: 2.8em;
}
.cloud-size-13 {
  opacity: 1;
  font-size: 2.9em;
}
.cloud-size-14 {
  opacity: 1;
  font-size: 3em;
}
</style>