<template>
  <div>

    <v-row>
      <v-col>
        <h2 class="pb-3">{{ $t('DFGwwC5keNzT-l7k6GHji') }}</h2>
        <div class="pb-3 ">{{ $t('0XCYfCHNiCIcqRnWexmEK') }}</div>
      </v-col>
    </v-row>

    <v-row
      v-for="(value, param, index) in {
          first_ts: report.web_archive.first_ts,
          last_ts: report.web_archive.last_ts,
          years_counter: report.web_archive.years_counter,
          snap_counter: report.web_archive.snap_counter,
          }"
      :key="index"
      class="table-line"
    >
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="4"
        class="title-table"
      >

        <template v-if="param == 'first_ts'">
          {{ $t('rPBCRcxIo8pulGIpt2Ltd') }}
        </template>
        <template v-else-if="param === 'last_ts'">
          {{ $t('VneKKSNd0UYW65h_xF9rs') }}
        </template>
        <template v-else-if="param === 'snap_counter'">
          {{ $t('KkpN5GDtWH3ECGAlvRtsP') }}
        </template>
        <template v-else-if="param === 'years_counter'">
          {{ $t('yuCZe89D4OH9ECpnHSBjK') }}
        </template>

      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="8"
        lg="8"
      >
        <template v-if="param == 'snap_counter' || param =='years_counter'">
          {{ $n(value) }}
        </template>

        <template v-if="param == 'first_ts'">
          {{ $t('3V2fi1qb7-E-j5EqCp6cL') }}:
          <JsUrl
            :disabled="report.demo"
            :url="`https://web.archive.org/web/${value}/${report.domain}`"
            :title="$t('kiWTqBh1uuYxoiG8ULOQ6')"
            class="text-decoration-underline"
          >
            {{ $moment.unix(value).format('MMMM Do YYYY') }}</JsUrl>&nbsp;
          <span class="text--disabled">({{ $moment.unix(value).fromNow() }})</span>

          <v-expansion-panels class="pt-2">

            <v-expansion-panel :disabled="!report.web_archive.first_content.length_symbols">
              <v-expansion-panel-header>
                {{ $t('jxeIzh0nHO8bI3ioawQ9K') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <Content :content="report.web_archive.first_content" />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel :disabled="!Boolean(report.web_archive.first_content.length_symbols || Object.keys(report.web_archive.first_content.builtwith).length)">
              <v-expansion-panel-header>
                {{ $t('ItJ50Fsz9sJV_8MZpCuYG') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <BuiltWith :builtwith="report.web_archive.first_content.builtwith" />
              </v-expansion-panel-content>
            </v-expansion-panel>

          </v-expansion-panels>

        </template>

        <template v-if="param == 'last_ts'">
          {{ $t('4UAZNLiqpFWqjuPWIb0I4') }}:
          <JsUrl
            :disabled="report.demo"
            :url="`https://web.archive.org/web/${value}/${report.domain}`"
            :title="$t('kiWTqBh1uuYxoiG8ULOQ6')"
            class="text-decoration-underline"
          >
            {{ $moment.unix(value).format('MMMM Do YYYY') }}</JsUrl>&nbsp;
          <span class="text--disabled">({{ $moment.unix(value).fromNow() }})</span>

          <v-expansion-panels class="pt-2">

            <v-expansion-panel :disabled="!report.web_archive.last_content.length_symbols">
              <v-expansion-panel-header>
                {{ $t('Ztk1I-3f_fF55MXuEonhS') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <Content :content="report.web_archive.last_content" />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel :disabled="!Boolean(report.web_archive.last_content.length_symbols || Object.keys(report.web_archive.last_content.builtwith).length)">
              <v-expansion-panel-header>
                {{ $t('xcg99f1tqjqauLgMR_pk0') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <BuiltWith :builtwith="report.web_archive.last_content.builtwith" />
              </v-expansion-panel-content>
            </v-expansion-panel>

          </v-expansion-panels>

        </template>

      </v-col>

    </v-row>

  </div>
</template>

<script>
import JsUrl from '@/components/JsUrl'
import { mapGetters } from 'vuex'

import Content from '@/components/reports/Content'
import BuiltWith from '@/components/reports/BuiltWith'

export default {
  name: 'WebArchive',

  components: {
    JsUrl, Content, BuiltWith
  },

  computed: {
    ...mapGetters(['report']),
  }
}
</script>

<style scoped>
.table-line {
  /* background-color: rgba(125, 125, 125, 0.1); */
  border-bottom: solid 1px rgba(125, 125, 125, 0.4);
}
.table-line:last-child {
  border-bottom: none;
}
.title-table {
  background-color: rgba(125, 125, 125, 0.1);
}
</style>